<template>
  <b-overlay
    :show="
      $store.state.hearingStore.hearingLoading &&
      !$store.state.hearingStore.allHearings.length
    "
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <!-- types -->
    <b-card
      class="m-0 p-0 rounded-0"
      no-body
      :style="
        isDark
          ? 'background-color: #343d55; border-color: #343d55'
          : 'background-color: #f3f2f7; border-color: #e6e9eb'
      "
    >
      <template #header>
        <b-col>
          <b-row class="justify-content-between">
            <b-col class="p-0 m-0">
              <p class="p-0 m-0">Listed Cases</p>
            </b-col>
            <b-col>
              <b-row class="justify-content-end">
                <hearing-filter class="pr-50"></hearing-filter>
                <hearing-download-button></hearing-download-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </template>
    </b-card>
    <div :type="$store.state.hearingStore.allHearings">
      <div v-for="causelist in $store.state.hearingStore.allHearings">
        <b-card
          no-body
          class="m-0 p-0 rounded-0"
          :style="
            isDark
              ? 'background-color: #343d55; border-color: #343d55'
              : 'background-color: #f3f2f7; border-color: #e6e9eb'
          "
          :header="causelist.date"
          header-text-variant="black"
          header-raduis="0"
          header-class="m-0 p-1 rounded-0"
        >
          <template #header>
            <b-col class="col-12 text-center">
              <b-row class="justify-content-between">
                <h4 class="text-black">{{ causelist.date }}</h4>
                <div>
                  <export-hearing-by-date
                    :date="causelist.date"
                  ></export-hearing-by-date>
                </div>
              </b-row>
            </b-col>
          </template>
          <causelist-list :items="causelist.data" :hearing_date="causelist.date">
          </causelist-list>
        </b-card>
      </div>
    </div>
    <b-card
      v-if="
        !$store.state.hearingStore.allHearings.length &&
        !$store.state.hearingStore.hearingLoading
      "
    >
      <p class="text-center mt-5">Record Not Found!</p>
    </b-card>
  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { VueGoodTable } from 'vue-good-table'
import ExportHearingByDate from '../../hearings/components/ExportHearingByDate.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import HearingFilter from '../HearingFilter.vue'
import HearingDisplayCourt from '../../hearings/components/HearingDisplayCourt.vue'
import HearingDownloadButton from '../HearingDownloadButton.vue'
import CauselistList from '../components/CauselistList.vue'

import {
  BCard,
  BCardHeader,
  BButton,
  BCollapse,
  BCardBody,
  BTable,
  BBadge,
  BCol,
  BRow,
  BDropdown,
  BDropdownItem,
  BCardText,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BCardBody,
    BCardText,
    BTable,
    BBadge,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    ExportHearingByDate,
    HearingDisplayCourt,
    HearingFilter,
    HearingDownloadButton,
    CauselistList
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {},
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))

    return {
      fields: [
        { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
        { key: 'court', label: 'Court', sortable: false },
        { key: 'title', sortable: false },
        { key: 'actions' }
      ],
      court: userData.court.label,
      collapseType: 'border'
    }
  },
  beforeCreate() {
    this.$store.dispatch('hearingStore/fetchHearings')
  },
  beforeMount() {},
  methods: {
    checkIfInteger(court_number) {
      // Parse the input as a number and check if it's an integer
      const parsedValue = parseInt(court_number, 10)
      return Number.isInteger(parsedValue)
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      // let href = url.replace(
      //   'https://s3.mymunshi.in/',
      //   'https://app.mymunshi.in/exports'
      // )
      this.downloadAs(url, `${uu}-order-${dd}.pdf`)
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  }
}
</script>
