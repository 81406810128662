var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-0 m-0 rounded-0",attrs:{"no-body":""}},[_c('b-table',{ref:"refInvoiceListTable",staticClass:"position-relative",attrs:{"items":_vm.ctitleGroup,"fields":_vm.fields,"responsive":"","primary-key":_vm.ctitleGroup._id,"show-empty":"","empty-text":"No records found"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('router-link',{staticClass:"mb-1 text-primary",attrs:{"to":{
          name: data.item.hc_name.includes('oc-')
            ? 'dashboard-other-matter-preview'
            : 'dashboard-matter-preview',
          params: { id: data.item._id }
        },"target":"_blank"}},[_c('h6',{staticClass:"mb-1 text-primary"},[_vm._v(" "+_vm._s(data.item.title)+" ")])])]}},{key:"cell(unique_id)",fn:function(data){return [_c('b-col',[(Object.keys(data.item).includes('file_no'))?_c('small',[_vm._v("File No.: "+_vm._s(data.item.file_no))]):_vm._e(),_c('div',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getUniqueId(data.item))+" ")])]),(Object.keys(data.item).includes('cause_name'))?_c('div',[(data.item.cause_name)?_c('small',[_vm._v(" ["+_vm._s(data.item.cause_name)+"] ")]):_vm._e()]):_vm._e()])]}},{key:"cell(court)",fn:function(data){return [_c('b-col',[(Object.keys(data.item).includes('ctitle'))?_c('div',[_c('small',{staticClass:"text-secondary font-italic"},[_vm._v(_vm._s(data.item.ctitle))])]):_vm._e(),(Object.keys(data.item).includes('causelist_type'))?_c('div',[(data.item.causelist_type)?_c('small',{staticClass:"text-secondary"},[_vm._v("("+_vm._s(data.item.causelist_type)+")")]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('list_type'))?_c('div',[(data.item.list_type)?_c('small',{staticClass:"text-secondary"},[_vm._v("(List Type: "+_vm._s(data.item.list_type)+")")]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('stats'))?_c('div',[(data.item.stats)?_c('div',[(
                Object.keys(data.item.stats).includes('total_fresh_cases') ||
                Object.keys(data.item.stats).includes('total_add_cases') ||
                Object.keys(data.item.stats).includes('total_ia_cases') ||
                Object.keys(data.item.stats).includes('ordinary') ||
                Object.keys(data.item.stats).includes('urgent')
              )?_c('div',[(
                  data.item.stats.total_fresh_cases > 0 ||
                  data.item.stats.total_add_cases > 0 ||
                  data.item.stats.total_ia_cases > 0 ||
                  data.item.stats.ordinary > 0 ||
                  data.item.stats.urgent > 0
                )?_c('small',[_vm._v(" ( "),(
                    Object.keys(data.item.stats).includes('total_fresh_cases')
                  )?_c('small',{staticClass:"text-secondary"},[_vm._v("F-"+_vm._s(data.item.stats.total_fresh_cases)+" ")]):_vm._e(),(
                    Object.keys(data.item.stats).includes('total_add_cases')
                  )?_c('small',{staticClass:"text-secondary"},[_vm._v("ADD-"+_vm._s(data.item.stats.total_add_cases)+" ")]):_vm._e(),(
                    Object.keys(data.item.stats).includes('total_ia_cases')
                  )?_c('small',{staticClass:"text-secondary"},[_vm._v("APP-"+_vm._s(data.item.stats.total_ia_cases)+" ")]):_vm._e(),(Object.keys(data.item.stats).includes('ordinary'))?_c('small',{staticClass:"text-secondary"},[_vm._v("ORD-"+_vm._s(data.item.stats.ordinary)+" ")]):_vm._e(),(Object.keys(data.item.stats).includes('urgent'))?_c('small',{staticClass:"text-secondary"},[_vm._v("URG-"+_vm._s(data.item.stats.urgent)+" ")]):_vm._e(),_vm._v(" ) ")]):_vm._e()]):_vm._e()]):_vm._e()]):_vm._e(),(
            Object.keys(data.item).includes('serial_number') ||
            Object.keys(data.item).includes('court_number')
          )?_c('div',[(
              data.item.court_number !== null || data.item.serial_number > 0
            )?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" Listed: ")]):_vm._e(),(Object.keys(data.item).includes('serial_number'))?_c('small',[(data.item.serial_number > 0)?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" [ Sr No. "+_vm._s(data.item.serial_number)+" ] ")]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('court_number'))?_c('small',[(_vm.checkIfInteger(data.item.court_number))?_c('small',[_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" - [ Court No. "+_vm._s(data.item.court_number <= 0 ? _vm.getStringCourtNumber(data.item.court_number) : data.item.court_number)+" ] ")])]):_c('small',[(data.item.court_number !== null)?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" - [ Court No. "+_vm._s(data.item.court_number)+" ] ")]):_vm._e()])]):_vm._e(),(Object.keys(data.item).includes('transferred_court'))?_c('small',[(
                data.item.transferred_court != null &&
                data.item.transferred_court != -1
              )?_c('small',{staticClass:"text-success font-weight-bolder"},[_vm._v(" - [ TF to CT "+_vm._s(data.item.transferred_court <= 0 ? _vm.getStringCourtNumber(data.item.transferred_court) : data.item.transferred_court)+" ] ")]):_vm._e()]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('judges'))?_c('div',[(data.item.judges)?_c('div',[(data.item.judges.length)?_c('small',{staticClass:"text-black font-weight-bold"},[_vm._v(_vm._s(data.item.hc_name.includes('hc') || data.item.hc_name.includes('sci') ? data.item.judges.length > 1 ? 'JUSTICES' : 'JUSTICE' : '')+" "+_vm._s(data.item.judges.join(', '))+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(
            data.item.hc_name.includes('hc') ||
            data.item.hc_name.includes('sci')
          )?_c('div',[(
              Object.keys(data.item).includes('serial_number') &&
              Object.keys(data.item).includes('court_number')
            )?_c('small',[(
                data.item.court_number !== null && data.item.serial_number > 0
              )?_c('small',[_c('hearing-display-court',{attrs:{"date":_vm.date,"court_id":data.item.court_id,"court_no":Object.keys(data.item).includes('transferred_court') &&
                  data.item.transferred_court != null &&
                  data.item.transferred_court != -1
                    ? data.item.transferred_court
                    : data.item.court_number,"serial_no":data.item.serial_number}})],1):_vm._e()]):_vm._e()]):_vm._e()])]}},{key:"cell(actions)",fn:function(data){return [_c('b-row',{staticClass:"text-nowrap"},[_c('b-col',{staticClass:"align-self-center col-8",attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"mb-1 text-primary",attrs:{"to":{
                name: data.item.hc_name.includes('oc-')
                  ? 'dashboard-other-matter-preview'
                  : 'dashboard-matter-preview',
                params: { id: data.item._id }
              },"target":"_blank"}},[_c('b-badge',{staticClass:"badge-round mr-1",attrs:{"variant":"primary","pill":""}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("matter-" + (data.item._id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}}),_c('span',[_vm._v("View")])],1)],1)],1),(Object.keys(data.item).includes('last_order_url'))?_c('div',[(data.item.last_order_url)?_c('b-badge',{staticClass:"badge-round mt-2 cursor-pointer",attrs:{"variant":"light","pill":""},on:{"click":function($event){return _vm.exportJudgment({
                  url: data.item.last_order_url,
                  date: data.item.last_order.datetime,
                  matter_id: data.item.unique_id
                })}}},[_c('feather-icon',{staticClass:"mr-25 text-primary",attrs:{"id":("order-" + (data.item._id) + "-preview-icon"),"icon":"Link2Icon","size":"16"}}),_c('span',{staticClass:"text-primary"},[_vm._v("Last Order")])],1):_vm._e()],1):_vm._e()])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }