<template>
  <app-collapse>
    <!-- On Priority Cases -->
    <app-collapse-item
      v-if="Object.keys(groupedItems.onPriority).length > 0"
      :title="
        'On Priority (' + Object.keys(groupedItems.onPriority).length + ')'
      "
      :isVisible="true"
    >
      <causelist-item :items="groupedItems.onPriority" :hearing_date="date">
      </causelist-item>
    </app-collapse-item>

    <!-- Other Courts -->
    <div v-for="(ctitleGroup, ctitle) in groupedItems.others" :key="ctitle">
      <app-collapse-item
        :title="ctitle + ' (' + ctitleGroup.length + ')'"
        :isVisible="true"
        v-if="Object.keys(groupedItems.others).length > 0"
      >
        <causelist-item :items="ctitleGroup" :hearing_date="date">
        </causelist-item>
      </app-collapse-item>
    </div>
  </app-collapse>
</template>

<script>
// Your existing imports
import HearingDisplayCourt from '../../hearings/components/HearingDisplayCourt.vue'
import axios from 'axios'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CauselistItem from './CauselistItem.vue'

import {
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BBadge,
  BButton,
  BCollapse,
  VBTooltip,
  VBToggle
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BBadge,
    HearingDisplayCourt,
    BCollapse,
    BButton,
    AppCollapse,
    AppCollapseItem,
    CauselistItem
    // Include any other components as necessary
  },
  directives: { 'b-tooltip': VBTooltip, 'b-toggle': VBToggle },
  props: {
    items: {
      type: Array,
      required: true
    },
    hearing_date: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hearings: this.items,
      date: this.hearing_date
    }
  },
  computed: {
    groupedItems() {
      const onPriorityItems = []
      const groupedOtherItems = {}

      // Function to determine sorting order based on hc_name
      const sortByHcName = (a, b) => {
        const hcNameA = a.hc_name ?? ''
        const hcNameB = b.hc_name ?? ''

        if (hcNameA.startsWith('sci')) return -1 // Supreme Court first
        if (hcNameB.startsWith('sci')) return 1

        if (hcNameA.startsWith('hc-')) return -1 // High Courts next
        if (hcNameB.startsWith('hc-')) return 1

        if (hcNameA.startsWith('ED-')) return -1 // District Courts next
        if (hcNameB.startsWith('ED-')) return 1

        if (hcNameA.startsWith('tribunal')) return -1 // Tribunals next
        if (hcNameB.startsWith('tribunal')) return 1

        return 0 // Others last
      }

      // First, sort the items by `hc_name`
      const sortedItems = this.items.sort(sortByHcName)

      // Then, group after sorting
      sortedItems.forEach((item) => {
        if (item.user_priority) {
          onPriorityItems.push(item)
        } else {
          const ctitle = item.ctitle || 'No Title'
          if (!groupedOtherItems[ctitle]) {
            groupedOtherItems[ctitle] = []
          }
          groupedOtherItems[ctitle].push(item)
        }
      })

      // Return the final structure
      return {
        onPriority: onPriorityItems, // Already sorted by `hc_name`
        others: groupedOtherItems // Grouped by `ctitle` and already sorted by `hc_name`
      }
    }
  },
  methods: {
    getCollapseId(ctitle) {
      // Convert ctitle to lowercase and replace spaces with hyphens
      const formattedCtitle = ctitle.replace(/\s+/g, '-')
      return `${formattedCtitle}-${this.hearing_date}`
    },
    navigateToDetail(data) {
      this.$router.push({
        name: data.hc_name.includes('oc-')
          ? 'dashboard-other-matter-preview'
          : 'dashboard-matter-preview',
        params: { id: data._id }
      })
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },
    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    },
    getStats(stats) {
      let sList = []
      let sResult = ''

      if (stats != null) {
        if (
          stats.hasOwnProperty('total_fresh_cases') &&
          stats['total_fresh_cases'] !== null &&
          typeof stats['total_fresh_cases'] === 'number' &&
          stats['total_fresh_cases'] > 0
        ) {
          sList.push('F-' + stats['total_fresh_cases'])
        }

        if (
          stats.hasOwnProperty('total_add_cases') &&
          stats['total_add_cases'] !== null &&
          typeof stats['total_add_cases'] === 'number' &&
          stats['total_add_cases'] > 0
        ) {
          sList.push('Add-' + stats['total_add_cases'])
        }

        if (
          stats.hasOwnProperty('total_ia_cases') &&
          stats['total_ia_cases'] !== null &&
          typeof stats['total_ia_cases'] === 'number' &&
          stats['total_ia_cases'] > 0
        ) {
          sList.push('App-' + stats['total_ia_cases'])
        }

        if (
          stats.hasOwnProperty('ordinary') &&
          stats['ordinary'] !== null &&
          typeof stats['ordinary'] === 'number' &&
          stats['ordinary'] > 0
        ) {
          sList.push('Ord-' + stats['ordinary'])
        }

        if (
          stats.hasOwnProperty('urgent') &&
          stats['urgent'] !== null &&
          typeof stats['urgent'] === 'number' &&
          stats['urgent'] > 0
        ) {
          sList.push('Urg-' + stats['urgent'])
        }
      }

      if (sList.length > 0) {
        sResult = '( '
        sResult += sList.join(' ')
        sResult += ' )'
      }

      return sResult
    }
  }
}
</script>

<style scoped>
.text-primary {
  color: #007bff;
}
.text-secondary {
  color: #6c757d;
}
</style>
